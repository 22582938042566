import { useApolloClient } from '@apollo/react-hooks'
import { LPCTCardProps } from '@berlitzplatforms/micro.ui.lpct-card/lib'
import PortalLearningPath from '@classes/LearningPath'
import TestResults from '@classes/TestResults'
import { usePortalFeatures } from '@components/RoleBasedView'
import { RegistrationInfo, RegistrationMaterial } from '@interfaces/Student'
import { ITestResultsQueryRes } from '@interfaces/TestResults'
import { PUT_REGISTRATION_FLEX_LEVEL_STATUS } from '@queries/registrations'
import { GET_TEST_RESULTS, RESET_TEST } from '@queries/testResults'
import { CURRICULUM_TEST_STATUS } from '@utils/constants'
import { ApolloError, ApolloQueryResult } from 'apollo-client'
import Router from 'next/router'
import { useState } from 'react'
import { useIntl } from 'react-intl'
interface LPCTCardPropsWithAttempt extends LPCTCardProps {
  attempt?: number
}
type IUseCTCardState = () => [
  IGetState,
  {
    loading: boolean
    data: LPCTCardPropsWithAttempt | undefined
    error: ApolloError | undefined
    btnLoading: boolean
  },
]
interface GetStateProps {
  username: string
  url: string
  registration: RegistrationInfo
  learningPath: PortalLearningPath
}
interface APIProps {
  lpid: string
  username: string
}
interface ResetProps {
  cmid: number
  username: string
}
type IGetState = (props: GetStateProps) => void
const validErrors = [
  `'attempt_number' does not exist or it is not a Curriculum Test Program or Student does not have attempts`,
  'GraphQL error: Learning Path ID for this CT is not valid.',
  'GraphQL error: There are no Curriculum Test Results for this Student.',
  'GraphQL error: User is not assigned to the learning path ID.',
  `GraphQL error: Incorrect attempt number. Try using 'All', 'Latest' or valid attempt number.`,
]
const useCTCardState: IUseCTCardState = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApolloError | undefined>()
  const [data, setData] = useState<LPCTCardProps>()
  const [btnLoading, setBtnLoading] = useState(false)
  const client = useApolloClient()
  const { features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })
  const updateSFScores = ({ RegistrationId, CurriculumTestHighestTotalScore, CurriculumTestLatestTotalScore }) => {
    client.mutate({
      mutation: PUT_REGISTRATION_FLEX_LEVEL_STATUS,
      variables: {
        registrationInput: [
          {
            RegistrationInfo: {
              RegistrationId,
              CurriculumTestHighestTotalScore,
              CurriculumTestLatestTotalScore,
            },
          },
        ],
      },
    })
  }
  const fetchTestResults = ({ lpid, username }: APIProps) => {
    return client.query<ITestResultsQueryRes>({
      query: GET_TEST_RESULTS,
      variables: {
        username,
        lpid,
        attempt_number: 'all',
      },
      fetchPolicy: 'network-only',
    })
  }
  const resetTest = ({ cmid, username }: ResetProps) => {
    return client.query<ITestResultsQueryRes>({
      query: RESET_TEST,
      variables: {
        username,
        cmid,
      },
      fetchPolicy: 'network-only',
    })
  }
  const fetchMultiTestResults = async (materials: RegistrationMaterial[], username: string) => {
    const requests = materials
      .filter(({ IsTest }) => IsTest)
      .map((material) => fetchTestResults({ lpid: material.LPID, username }))
    return await Promise.all(requests)
  }

  const getState: IGetState = async ({ username, url, registration, learningPath }) => {
    let res: ApolloQueryResult<ITestResultsQueryRes>[] | undefined
    let err: ApolloError | undefined
    let testResults: TestResults | undefined
    const maxAttempts = registration?.MaxNumberTestAttempts || 0
    const testStatus = registration?.TestStatus || CURRICULUM_TEST_STATUS.LOCKED
    const expired = testStatus === CURRICULUM_TEST_STATUS.EXPIRED
    setLoading(true)
    try {
      res = await fetchMultiTestResults(registration.Materials, username)
      testResults = new TestResults(
        res.map((item) => item.data.getTestResult),
        maxAttempts,
        registration,
        learningPath
      )
    } catch (e) {
      err = e as ApolloError
      if (!validErrors.includes(err?.message)) {
        setError(err)
      }
    }
    setLoading(false)
    const proceedToTest = async () => {
      setError(undefined)
      setBtnLoading(true)
      const activeCTID = JSON?.parse(localStorage.getItem('activeCTID') || '""')
      let materials = registration.Materials
      let lpId = learningPath?.CtEntryPoint?.Id
      if (registration?.ElectiveRequired !== 'Assigned' && registration?.ElectiveRequired !== 'Required') {
        materials = registration.Materials.filter((material) => material.LPID === activeCTID)
        lpId = activeCTID || learningPath?.CtEntryPoint?.Id
      }
      try {
        const temp = await fetchMultiTestResults(materials, username)
        const newTestResults = new TestResults(
          temp.map((item) => item.data.getTestResult),
          maxAttempts,
          registration,
          learningPath
        )
        if (newTestResults.HasAttemptsLeft) {
          if (!newTestResults.IsFirstAttempt) {
            const requests = newTestResults.data.map((item) =>
              resetTest({ cmid: item.cmid, username: item.student_id })
            )
            await Promise.all(requests)
          }
          localStorage.setItem('CTrefreshREF', '')
          setBtnLoading(false)
          return features.cr8.redirectToCR8(lpId, url)
        } else {
          setBtnLoading(false)
          window.location.reload()
        }
      } catch (e) {
        setBtnLoading(false)
        const refetchError = e as ApolloError
        if (validErrors.includes(refetchError?.message)) {
          return features.cr8.redirectToCR8(learningPath?.CtEntryPoint?.Id, url)
        } else {
          setError(refetchError)
        }
      } finally {
        setBtnLoading(false)
      }
    }
    const expiredMessage = intl.formatMessage({
      id: 'Access to the test has been locked as your course for your next level has been created.',
      defaultMessage: 'Access to the test has been locked as your course for your next level has been created.',
    })
    const commonProps = {
      attempt: testResults?.NumberOfAttempts,
      subtitle: expired
        ? expiredMessage
        : intl.formatMessage({
            id: 'Complete the test to show how much you learnt in this level.',
            defaultMessage: 'Complete the test to show how much you learnt in this level.',
          }),
      linkModalMsg: intl.formatMessage({
        id: 'What is a curriculum test?',
        defaultMessage: 'What is a curriculum test?',
      }),
      whatModalTxt: {
        title: intl.formatMessage({
          id: 'More about the curriculum test',
          defaultMessage: 'More about the curriculum test',
        }),
        desc: intl.formatMessage({
          id: 'The curriculum test is made up of 40 random questions to test your skills. You can attempt the test 3 times. Achieve 75% or more to pass. Your result will be added to your certificate.',
          defaultMessage:
            'The curriculum test is made up of 40 random questions to test your skills. You can attempt the test 3 times. Achieve 75% or more to pass. Your result will be added to your certificate.',
        }),
        closeBtnLabel: 'Tap to close',
      },
      expired,
    }
    const defaultState = () => ({
      state: 'Locked',
      stateText: intl.formatMessage({ id: 'Locked', defaultMessage: 'Locked' }),
      title: intl.formatMessage({ id: 'Curriculum test locked', defaultMessage: 'Curriculum test locked' }),
      onBtnClick: () => Router.push('/support/contact-us/?id=feedback&cat=Other'),
      btnLabel: intl.formatMessage({ id: 'Contact us', defaultMessage: 'Contact us' }),
      ...commonProps,
    })
    const unlockedState = () => ({
      state: 'Unlocked',
      stateText: intl.formatMessage({ id: 'Unlocked', defaultMessage: 'Unlocked' }),
      title: intl.formatMessage({ id: 'Curriculum test', defaultMessage: 'Curriculum test' }),
      onBtnClick: () => {
        localStorage.setItem('activeCTID', JSON?.stringify(testResults?.getCurrentCTID()))
        proceedToTest()
      },
      btnLabel: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }),
      ...commonProps,
    })
    const unlockedWithAttempts = () => ({
      ...commonProps,
      state: 'Completed',
      stateText: intl.formatMessage({ id: 'Completed', defaultMessage: 'Completed' }),
      title: expired
        ? intl.formatMessage({ id: 'Curriculum test locked', defaultMessage: 'Curriculum test locked' })
        : intl.formatMessage({ id: 'Curriculum test', defaultMessage: 'Curriculum test' }),
      subtitle: expired
        ? expiredMessage
        : intl.formatMessage(
            {
              id: 'ct-attmempts-left',
              defaultMessage: '{num, plural, =0 {No attempts left.} one {# attempt left.} other {# attempts left.}}',
            },
            { num: testResults?.AttemptsLeft || 0 }
          ),
      btnLabel: testResults?.HasAttemptsLeft
        ? intl.formatMessage({ id: 'Try again', defaultMessage: 'Try again' })
        : undefined,
      onBtnClick: () => {
        localStorage.setItem('activeCTID', JSON?.stringify(testResults?.getCurrentCTID()))
        proceedToTest()
      },
      highestScore: {
        value: testResults?.getPercentage(testResults.HighestResult) || 0,
        label: !testResults?.HasOnlyOneResult
          ? intl.formatMessage({ id: 'Highest result', defaultMessage: 'Highest result' })
          : intl.formatMessage({ id: 'View result', defaultMessage: 'View result' }),
        onClick: () => {
          localStorage.removeItem('CTrefreshREF')
          localStorage.setItem('activeCTID', JSON?.stringify(testResults?.getCurrentCTID()))
          localStorage.setItem('activeLPID', JSON?.stringify(testResults?.getCurrentLPID()))
          Router.push(testResults?.HighestResultURL || '/learning-path')
        },
        pass: !!testResults?.isPass(testResults?.HighestResult),
      },
      recentScore:
        testResults?.HasOnlyOneResult || !testResults
          ? undefined
          : {
              value: testResults?.getPercentage(testResults.LatestResult) || 0,
              label: intl.formatMessage({ id: 'Latest result', defaultMessage: 'Latest result' }),
              onClick: () => {
                localStorage.removeItem('CTrefreshREF')
                localStorage.setItem('activeCTID', JSON?.stringify(testResults?.getCurrentCTID()))
                localStorage.setItem('activeLPID', JSON?.stringify(testResults?.getCurrentLPID()))
                Router.push(testResults?.LatestResultURL || '/learning-path')
              },
              pass: !!testResults?.isPass(testResults?.LatestResult),
            },
    })
    if (testResults?.HighestResult && testResults?.LatestResult) {
      const CurriculumTestHighestTotalScore = testResults?.getPercentage(testResults.HighestResult) || 0
      const CurriculumTestLatestTotalScore = testResults?.getPercentage(testResults.LatestResult) || 0
      updateSFScores({
        RegistrationId: registration.RegistrationId,
        CurriculumTestHighestTotalScore,
        CurriculumTestLatestTotalScore,
      })
    }
    if (testStatus === CURRICULUM_TEST_STATUS.EXPIRED) {
      return setData(testResults?.AttemptGroups?.length ? unlockedWithAttempts() : defaultState())
    }
    if (testStatus === CURRICULUM_TEST_STATUS.LOCKED) {
      return setData(defaultState())
    }
    if (testResults?.IsFirstAttempt && testStatus === CURRICULUM_TEST_STATUS.UNLOCKED) {
      return setData(expired ? defaultState() : unlockedState())
    }
    if (testStatus === CURRICULUM_TEST_STATUS.UNLOCKED || testStatus === CURRICULUM_TEST_STATUS.COMPLETED) {
      return setData(unlockedWithAttempts())
    }
    setData(defaultState())
  }
  return [getState, { loading, data, error, btnLoading }]
}
export default useCTCardState
