import { RegistrationInfo } from '@interfaces/Student'
import { IAttemptGrps, IAttemptGrpScoreSummary, IQuizAttempt, ITestResults } from '@interfaces/TestResults'
import PortalLearningPath from './LearningPath'
class TestResults {
  data: ITestResults[]
  maxNumOfAttempts: number
  registration: RegistrationInfo
  learningPath: PortalLearningPath
  constructor(
    results: ITestResults[],
    maxNumOfAttempts: number,
    registration: RegistrationInfo,
    learningPath: PortalLearningPath
  ) {
    this.data = results
    this.maxNumOfAttempts = maxNumOfAttempts
    this.registration = registration
    this.learningPath = learningPath
  }

  generateURL = (result?: IAttemptGrpScoreSummary) => {
    return result ? `/activity?attempt_number=${result.attempt}&reg=${this.registration.RegistrationId}` : undefined
  }

  getPercentage = (result: IAttemptGrpScoreSummary) => {
    return Math.round((result?.correctAnswers / result?.totalQuestions) * 100)
  }

  isPass = (result?: IAttemptGrpScoreSummary) => {
    return result ? this.getPercentage(result) >= 75 : false
  }

  getCurrentCTID = () => {
    const languageLevel = this.learningPath?.Material?.MaterialLevel || ''
    const language = this.learningPath?.Material?.language || ''
    const CTID = this.data.find(
      (result) => result.learning_language == language && result.learning_level == languageLevel
    )
    return CTID?.lpid
  }
  getCurrentLPID = () => {
    return this.learningPath?.Id
  }

  get AttemptGroups(): IAttemptGrpScoreSummary[] {
    const currentCTLPID = this.getCurrentCTID()
    let attemptsArr
    if (this.registration?.ElectiveRequired == 'Assigned' || this.registration?.ElectiveRequired == 'Required') {
      attemptsArr = this.data
        .filter(Boolean)
        .map((results) => results.quizattempts.map((qAttempt) => ({ ...qAttempt, resultRef: results })))
    } else {
      attemptsArr = this.data
        .filter((result) => result && result.lpid === currentCTLPID)
        .map((results) =>
          results.quizattempts.map((qAttempt) => ({
            ...qAttempt,
            resultRef: results,
          }))
        )
    }
    const temp = ([] as IQuizAttempt[]).concat.apply([], attemptsArr).reduce((prev, current) => {
      prev[current.attempt_number] = prev[current.attempt_number] || []
      prev[current.attempt_number].push({
        correctAnswers: current.total_correct_answers,
        totalQuestions: current.total_questions,
        resultRef: current.resultRef,
      })
      return prev
    }, {} as IAttemptGrps)

    return Object.keys(temp).map((index) =>
      temp[index].reduce(
        (prev, current) => {
          prev.correctAnswers = current.correctAnswers + prev.correctAnswers
          prev.totalQuestions = current.totalQuestions + prev.totalQuestions
          prev.resultRefs.push(current.resultRef)
          prev.attempt = Number(index) || 0
          return prev
        },
        { attempt: 0, correctAnswers: 0, totalQuestions: 0, resultRefs: [] } as IAttemptGrpScoreSummary
      )
    )
  }

  get HighestResult() {
    return this.AttemptGroups?.sort((a, b) => b.correctAnswers - a.correctAnswers)?.[0]
  }

  get HighestResultURL() {
    return this.generateURL(this.HighestResult)
  }

  get LatestResult() {
    return this.AttemptGroups?.sort((a, b) => b.attempt - a.attempt)?.[0]
  }

  get LatestResultURL() {
    return this.generateURL(this.LatestResult)
  }

  get AttemptsLeft() {
    const testResult = this.data[0]
    const attempts = this.maxNumOfAttempts - (testResult?.number_of_attempts ?? this.maxNumOfAttempts)
    return attempts > 0 ? attempts : 0
  }

  get HasAttemptsLeft() {
    return !!this.AttemptsLeft
  }

  get HasOnlyOneResult() {
    return this.LatestResult?.resultRefs?.[0]?.number_of_attempts === 1
  }

  get NumberOfAttempts() {
    return this.LatestResult?.resultRefs?.[0]?.number_of_attempts
  }

  get IsFirstAttempt() {
    const temp = this.data?.reduce((prev, current) => {
      prev[current.number_of_attempts] = prev[current.number_of_attempts] || []
      prev[current.number_of_attempts].push({
        ...current,
      })
      return prev
    }, {} as any)

    const attemptGroups = Object.keys(temp).map((index) =>
      temp[index].reduce(
        (prev, current) => {
          prev.resultRefs.push(current)
          prev.noOfAttempts = Number(index) || 0
          return prev
        },
        { noOfAttempts: 0, resultRefs: [] } as any
      )
    )

    const latest = attemptGroups.sort((a, b) => b.attempt - a.attempt)?.[0]
    return latest.noOfAttempts === 0
  }
}

export default TestResults
